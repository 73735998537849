import React, { FC } from "react"

type ReviewsProps = {
    reviews: any[]
    swiperElRef: any
}

const ReviewHandles: FC<ReviewsProps> = ({ reviews, swiperElRef }) => {
    if (swiperElRef === null) return null
    return (
        <div className="hidden lg:mt-8 lg:flex lg:gap-4">
            <button
                className="prev-button rounded-full border border-brand p-3 text-brand hover:bg-brand hover:text-white"
                onClick={(e) => {
                    e.preventDefault()
                    if (swiperElRef.current) {
                        // @ts-ignore
                        swiperElRef.current.slickPrev()
                    }
                }}
            >
                <span className="sr-only">Previous Slide</span>
                <svg
                    className="h-5 w-5 -rotate-180 transform"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9 5l7 7-7 7"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                    />
                </svg>
            </button>

            <button
                className="next-button rounded-full border border-brand p-3 text-brand hover:bg-brand hover:text-white"
                onClick={(e) => {
                    e.preventDefault()
                    if (swiperElRef.current) {
                        // @ts-ignore
                        swiperElRef.current.slickNext()
                    }
                }}
            >
                <span className="sr-only">Next Slide</span>
                <svg
                    className="h-5 w-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9 5l7 7-7 7"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                    />
                </svg>
            </button>
        </div>
    )
}

export default ReviewHandles
